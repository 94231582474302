import { useEffect, useRef, useState } from "react";
import levels from "../../levels";
import { getExamsList } from "../../api/constants";

const examTypes = [
  "Zählen von 1 bis 10",
  "Addition bis 10",
  "Subtraktion bis 10",
  "Vergleiche von Zahlen bis 10",
  "Multiplikation bis 10",
  "Zählen bis 100",
  "Addition bis 100",
  "Subtraktion bis 100",
  "Vergleiche von Zahlen bis 100",
  "Umrechnung cm/mm/m"
];

function Map({
  onMapClick,
  onFreeStyleClick,
}: {
  onMapClick: (index: number) => void;
  onFreeStyleClick: (topic: string) => void;
}) {
  const [pageInitiated, setPageInitiated] = useState(false);
  const [introFinished, setIntroFinished] = useState(false);
  const [openFreeStyleModal, setOpenFreeStyleModal] = useState(false);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  const videoItem = useRef<HTMLVideoElement>(null);
  const clickBtn = useRef<HTMLAudioElement>(null);
  const bgMusic = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (pageInitiated) {
      const exams = getExamsList();
      try {
        let currentLevel = 0; // Get exam id from URL
        exams.forEach((exam, index) => {
          if (exam.status === "success") {
            levels[index].status = "success";
            currentLevel = index + 1;
          }
          levels[index].examId = exam._id;
        });
        levels.forEach((level, index) => {
          if (index === currentLevel) {
            levels[index].status = "active";
          }
        });
      } catch (error) {}
      setIntroFinished(true);
      if (bgMusic.current) {
        bgMusic.current.volume = 0.35;
        bgMusic.current.play();
      }
    }
  }, [pageInitiated]);

  return (
    <>
      <audio controls={false} ref={clickBtn} src="/click.mp3" />
      <audio controls={false} ref={bgMusic} src="/bg_music.mp3" loop />
      {!pageInitiated && (
        <div className="intro_holder">
          <img alt="intro" src="/intro_img.jpg" className="intro_bg" />
          <img
            alt="bg"
            src="/enter_btn.png"
            className="action_btn"
            onClick={() => {
              if (clickBtn.current) {
                clickBtn.current.play();
              }
              setTimeout(() => {
                setPageInitiated(true);
              }, 500);
            }}
          />
        </div>
      )}
      {pageInitiated && !introFinished && (
        <video
          ref={videoItem}
          src="/intro_vid.mp4"
          autoPlay={true}
          controls={false}
          style={{ width: "100svw" }}
        ></video>
      )}
      {introFinished && pageInitiated && (
        <div className="map_holder">
          {openFreeStyleModal && (
            <div
              style={{
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                position: "fixed",
                zIndex: 150,
                background: "white",
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                justifyContent: "center",
                alignItems: "center",
                overflow: "auto",
              }}
              onClick={(e) => {
                e.preventDefault();
                setOpenFreeStyleModal(false);
              }}
            >
              <span className="text-xl">What topic do you wanna practice</span>
              {examTypes.map((et) => {
                return (
                  <button
                    className="btn btn-lg btn-wide text-white"
                    style={{
                      backgroundImage: "url(/images/btn_bg.png)",
                      backgroundSize: "cover",
                      backgroundPosition: "center -1px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onFreeStyleClick("%FREESTYLE%" + et);
                    }}
                  >
                    {et}
                  </button>
                );
              })}
            </div>
          )}
          <div
            style={{
              bottom: "0.5em",
              left: 0,
              position: "fixed",
              zIndex: 100,
              width: "100svw",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-lg btn-wide text-white"
              style={{
                backgroundImage: "url(/images/btn_bg.png)",
                backgroundSize: "cover",
                backgroundPosition: "center -1px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setOpenFreeStyleModal(true);
              }}
            >
              Practice
            </button>
          </div>
          <img
            alt="map"
            src="/map.png"
            className="map_main"
            onLoad={() => {
              setTimeout(() => {
                setBackgroundLoaded(true);
              }, 2000);
            }}
          />
          {backgroundLoaded &&
            levels.map(({ top, left, status }, index: number) => {
              return (
                <div
                  style={{
                    top: `${top * 1.5}svh`,
                    left: `${left * 1.5}svh`,
                    animationDelay: 0.2 * index + "s",
                  }}
                  className={
                    "platform" + (status === "active" ? " active" : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (status === "active") onMapClick(index);
                  }}
                  key={"platform-" + index}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      alt="platform"
                      key={"platform-img" + index}
                      src="/level_platform.png"
                      style={{ width: "100%", position: "absolute" }}
                    />
                    {status === "success" ? (
                      <img
                        alt="flag"
                        key={"platform" + index}
                        src="/flag.png"
                        style={{
                          width: "75%",
                          position: "absolute",
                          left: "12.5%",
                          top: "-2svh",
                        }}
                      />
                    ) : status === "locked" || status === "" ? (
                      <img
                        alt="lock"
                        key={"platform" + index}
                        src="/lock.png"
                        style={{
                          width: "50%",
                          position: "absolute",
                          left: "25%",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          position: "absolute",
                          top: "0.5svh",
                          fontSize: "3vh",
                          color: "white",
                          WebkitTextStroke: "1px black",
                          textAlign: "center",
                          width: "100%",
                          left: 0,
                        }}
                      >
                        {index + 1}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
}

export default Map;
