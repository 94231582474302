export const download = (url: string, logger: any = null): any => {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.responseType = "arraybuffer";
        if (logger) {
            const [log, setState] = logger;
            request.onprogress = (e) => {
                if (e.total > 0) { // Ensure total is greater than 0 to avoid division by zero
                    const progress = (e.loaded / e.total) * 100;
                    setState({ text: log, progress: progress.toFixed(2) });
                }
            };
        }
        console.log('====================================');
        console.log(request);
        console.log('====================================');
        request.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(request.response);
            } else {
                reject({
                    status: this.status,
                    statusText: request.statusText,
                });
            }
        };
        request.onerror = function () {
            reject({
                status: this.status,
                statusText: request.statusText,
            });
        };
        request.send();
    });
};
