import {
  finishExam,
  startExam,
  startFreestyle,
  submitAnswer,
} from "../../../api/exams";
import { useEffect, useReducer, useRef, useState } from "react";
/*import AudioIcon from "./icons/AudioIcon";*/
import SketchObjectDetector from "./inputs/drawing/SketchObjectDetector";
import { loadOnnxSession } from "../../../model/loadOnnxSession";
import NumberShuffler from "./inputs/NumberShuffler";
import LampIcon from "../../../icons/LampIcon";

let answered: { [qId: number]: number } = {};

const audioBaseURL = "https://asqme-ai.b-cdn.net/demo-pixel-audios/";

function Exam({
  examId,
  examCompleted,
}: {
  examId?: string;
  examCompleted: (passed: boolean) => void;
}) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState({
    text: "Loading OpenCV.js",
    progress: null,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modelInputShape = [1, 3, 256, 256];
  const [iouThreshold] = useState(0.7);
  const [scoreThreshold] = useState(0.25);
  const [number, setNumber] = useState("");
  const [transitionNextQuestion, setTransitionNextQuestion] = useState(false);

  const detectorProps = {
    session: session,
    modelInputShape: modelInputShape,
    iouThreshold: iouThreshold,
    scoreThreshold: scoreThreshold,
    maxOutputBoxesPerClass: 100,
    initCanvasWidth: 352,
    initCanvasHeight: 192,
  };

  const studentIdRef = useRef<string | undefined>(undefined);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioSrc, setAudioSrc] = useState<string>("");
  const examRef = useRef<any>(undefined);
  const [started, setStarted] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [isHintVisible, showHint] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, update] = useReducer((state: boolean) => {
    return !state;
  }, false);

  useEffect(() => {
    if (started) loadOnnxSession(setLoading, setSession, modelInputShape);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [started]);

  function parseHint(input: string) {
    // eslint-disable-next-line no-useless-escape
    const urlRegex = /IMG\((https:\/\/[^\)]+)\)\s?(.*)/;
    const match = input.match(urlRegex);

    if (match) {
      return { url: match[1], text: match[2] };
    } else {
      return { url: null, text: input };
    }
  }

  const nextClickHandle = async (question: any) => {
    if (answered[question._id]) {
      setAudioSrc("");
      if (studentIdRef.current) {
        if (step + 1 < examRef.current.questions.length) {
          if (answered[examRef.current.questions[step]._id]) {
            setStep(step + 1);
          } else {
            if (
              window.confirm(
                "you have not answered this question. are you sure?"
              )
            ) {
              setStep(step + 1);
            }
          }
        } else {
          if (answered[examRef.current.questions[step]._id]) {
            let resMsg = await finishExam(studentIdRef.current);
            alert(resMsg);
            setStarted(false);
            setStep(0);
            examCompleted(resMsg === "Credit Increased");
          } else {
            if (
              window.confirm(
                "you have not answered this question. are you sure?"
              )
            ) {
              let resMsg = await finishExam(studentIdRef.current);
              alert(resMsg);
              setStarted(false);
              setStep(0);
              examCompleted(resMsg === "Credit Increased");
            }
          }
        }
      }
      setNumber("");
    }
  };

  const answerSubmitHandle = async (question: any, input: string) => {
    if (answered[question._id]) {
      if (audioRef.current && question.correctAnswer !== "%STORY%") {
        try {
          audioRef.current.onended = null;
          if (!audioRef.current.paused) audioRef.current.pause();
          audioRef.current.src = audioBaseURL + "retry.mp3";
          audioRef.current.play();
          setAudioSrc(audioBaseURL + "retry.mp3");
          audioRef.current.onended = (e: any) => {
            setAudioSrc("");
            e.target.onended = null;
          };
        } catch (error) {}
      }
      showHint(false);
      setNumber("");
    } else {
      if (studentIdRef.current) {
        const { correctAnswer } = await submitAnswer(
          studentIdRef.current,
          question._id,
          input
        );

        answered[question._id] = correctAnswer === input ? 2 : 1;
        if (correctAnswer === input) {
          if (audioRef.current) {
            try {
              audioRef.current.onended = null;
              if (!audioRef.current.paused) audioRef.current.pause();
              audioRef.current.src =
                audioBaseURL +
                (Math.floor(Math.random() * 5) + 1) +
                "-correct.mp3";
              setAudioSrc(
                audioBaseURL +
                  (Math.floor(Math.random() * 5) + 1) +
                  "-correct.mp3"
              );
              audioRef.current.play();
              audioRef.current.onended = (e: any) => {
                setAudioSrc("");
                e.target.onended = null;
              };
            } catch (error) {}
          }
          setTransitionNextQuestion(true);
          setTimeout(async () => {
            try {
              await nextClickHandle(question);
            } catch (error) {}
            setTransitionNextQuestion(false);
          }, 4000);
        } else {
          if (audioRef.current) {
            try {
              audioRef.current.onended = null;
              if (!audioRef.current.paused) audioRef.current.pause();
              audioRef.current.src =
                audioBaseURL +
                (Math.floor(Math.random() * 5) + 1) +
                "-wrong.mp3";
              setAudioSrc(
                audioBaseURL +
                  (Math.floor(Math.random() * 5) + 1) +
                  "-wrong.mp3"
              );
              audioRef.current.play();
            } catch (error) {}
          }
        }
        update();
        showHint(false);
      }
    }
  };

  let content = null;
  if (!started) {
    content = (
      <div className="fixed w-full h-full flex flex-col items-center justify-center">
        <button
          onClick={async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const studentId = urlParams.get("student"); // Get exam id from URL
            if (examId) {
              if (studentId) {
                studentIdRef.current = studentId;
                if (!examId.includes("%FREESTYLE%")) {
                  const { journey } = await startExam(studentId, examId);
                  examRef.current = journey;
                } else {
                  const { questions } = await startFreestyle(
                    studentId,
                    examId.replace("%FREESTYLE%", "")
                  );
                  examRef.current = { questions };
                }
                answered = {};
                setStarted(true);
              } else {
                console.error("Student ID is missing from the URL");
              }
            } else {
              console.error("Exam ID is missing from the URL");
            }
          }}
          className="btn btn-lg btn-wide text-white"
          style={{
            backgroundImage: "url(/images/btn_bg.png)",
            backgroundSize: "cover",
            backgroundPosition: "center -1px",
            top: 0,
            left: 0,
          }}
        >
          Start Exam !
        </button>
      </div>
    );
  } else {
    const question = examRef.current.questions[step];
    if (question) {
      content = (
        <div className="w-full h-full flex flex-col items-center overflow-y-auto gap-4 pt-4 pb-4">
          <div className="card w-96 p-4" key={question._id}>
            <div
              className="card-body bg-base-100 ml-16"
              style={{
                borderRadius: "1em 1em 1em 0",
                transition: "all 1s",
                opacity:
                  isHintVisible && question.hintText !== "%STORY%" ? 1 : 0,
                height:
                  isHintVisible && question.hintText !== "%STORY%" ? "auto" : 0,
              }}
            >
              {parseHint(question.hintText)?.url && (
                <img src={parseHint(question.hintText)?.url || ""} alt="hint" />
              )}
              <p className="mt-2 mb-2">{parseHint(question.hintText)?.text}</p>
            </div>

            <div
              className="self-center"
              style={{
                position: "relative",
                width: "100%",
                height: "125px",
                zIndex: 10,
              }}
              onClick={(e) => {
                showHint(!isHintVisible);
              }}
            >
              <img
                src="/images/minion.png"
                alt="minion"
                style={{
                  position: "absolute",
                  left: -25,
                  transition: "all 0.3s",
                  top:
                    isHintVisible && question.hintText !== "%STORY%" ? 0 : 10,
                }}
                className={
                  "cursor-pointer w-40 mb-4" +
                  (audioSrc === "" ? " breathing" : " talking")
                }
              />
              {question.hintText !== "%STORY%" && (
                <button
                  className="btn btn-circle btn-lg mt-4 self-center"
                  style={{
                    backgroundImage: "url(/images/btn_bg.png)",
                    backgroundSize: "cover",
                    transition: "all 1s",
                    position: "absolute",
                    left: +150,
                    backgroundPosition: "center -1px",
                    opacity:
                      isHintVisible && question.hintText !== "%STORY%" ? 0 : 1,
                  }}
                >
                  <LampIcon />
                </button>
              )}
            </div>
            <div
              className="card-body bg-base-100 rounded-xl"
              style={{ zIndex: 11 }}
            >
              <p className="mt-2 mb-2">{question.question}</p>
              {answered[question._id] === 1 ? (
                <p className="mt-2">
                  <span style={{ color: "red" }}>
                    {question.correctAnswerReason}
                  </span>
                </p>
              ) : answered[question._id] === 2 ? (
                <p className="mt-2">
                  <span style={{ color: "green" }}>Well done!</span>{" "}
                </p>
              ) : null}
            </div>
            <audio ref={audioRef}></audio>
            {!answered[question._id] && (
              <>
                <div className="card-actions mt-4">
                  <div className="grid grid-cols-2 w-full gap-4">
                    {question.correctAnswer === "Yes" &&
                      ["Yes", "No"].map((pa: string) => (
                        <button
                          className="btn text-white"
                          style={{
                            backgroundImage: "url(/images/btn_bg.png)",
                            backgroundSize: "cover",
                            backgroundPosition: "center -1px",
                          }}
                          key={pa}
                          disabled={answered[question._id] !== undefined}
                          onClick={async () => {
                            await answerSubmitHandle(question, pa);
                          }}
                        >
                          {pa}
                        </button>
                      ))}
                  </div>
                </div>
                {!loading ? (
                  question.correctAnswer !== "%STORY%" &&
                  question.correctAnswer !== "Yes" &&
                  question.correctAnswer.split(",").length === 1 && (
                    <SketchObjectDetector
                      {...detectorProps}
                      setNumber={setNumber}
                      number={number}
                    />
                  )
                ) : (
                  <></>
                )}
                {question.correctAnswer.split(",").length > 1 && (
                  <NumberShuffler
                    input={question.correctAnswer}
                    onSequenceComplete={async (answer) => {
                      await answerSubmitHandle(question, answer);
                    }}
                  />
                )}
              </>
            )}
            {question.correctAnswer !== "%STORY%" &&
              question.correctAnswer !== "Yes" &&
              question.correctAnswer.split(",").length === 1 && (
                <div className="bg-white flex justify-center items-center rounded-xl mt-4">
                  <h1 className="text-6xl">{number}</h1>
                </div>
              )}
            <button
              className="btn text-white mt-4"
              style={{
                backgroundImage: "url(/images/btn_bg.png)",
                backgroundSize: "cover",
                backgroundPosition: "center -1px",
              }}
              disabled={
                (question.correctAnswer !== "%STORY%" &&
                  question.correctAnswer !== "Yes" &&
                  question.correctAnswer.split(",").length === 1 &&
                  number === "") ||
                (question.correctAnswer === "Yes" && !answered[question._id]) ||
                (question.correctAnswer.split(",").length > 1 &&
                  !answered[question._id]) ||
                transitionNextQuestion
              }
              key={number}
              onClick={async () => {
                if (question.correctAnswer === "%STORY%") {
                  answered[question._id] = 2;
                }
                await nextClickHandle(question);
                await answerSubmitHandle(question, number);
              }}
            >
              {answered[question._id] || question.correctAnswer === "%STORY%"
                ? !transitionNextQuestion
                  ? step < examRef.current.questions.length - 1
                    ? "Next"
                    : "Finish"
                  : "Loading next question..."
                : "Submit Answer"}
            </button>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="w-full h-full">
      <img
        alt={"background"}
        style={{ objectFit: "cover" }}
        className="fixed left-0 top-0 w-full h-full"
        src="/images/background.png"
      />
      {content}
    </div>
  );
}

export default Exam;
