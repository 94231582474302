import axios from "axios";

export async function chatOpenAI(text: string) {
    const data = {
        model: "gpt-3.5-turbo",
        messages: [
            {
                role: "system",
                content: "You are a Parrot pirate for a cartoon game of math who is alongside the kids during the math adventure, when they talk with you answer their questions in a friendly and nice tone as if you are actually that parrot. Also reply in a funny way to questions outside of this topic, that you will not reply to it as you are only a parrot pirate for math. Reply on the original language of the User."
            },
            {
                role: "user",
                content: text
            }
        ],
        temperature: 1,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0
    };

    const { data: result } = await axios.post('https://api.openai.com/v1/chat/completions', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer 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`
        }
    })
    return result
}