import React from "react";

function LampIcon() {
  return (
    <svg
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 32C11.12 32 10.3669 31.6869 9.7408 31.0608C9.1136 30.4336 8.8 29.68 8.8 28.8H15.2C15.2 29.68 14.8869 30.4336 14.2608 31.0608C13.6336 31.6869 12.88 32 12 32ZM7.2 27.2C6.74667 27.2 6.36693 27.0464 6.0608 26.7392C5.7536 26.4331 5.6 26.0533 5.6 25.6C5.6 25.1467 5.7536 24.7669 6.0608 24.4608C6.36693 24.1536 6.74667 24 7.2 24H16.8C17.2533 24 17.6336 24.1536 17.9408 24.4608C18.2469 24.7669 18.4 25.1467 18.4 25.6C18.4 26.0533 18.2469 26.4331 17.9408 26.7392C17.6336 27.0464 17.2533 27.2 16.8 27.2H7.2ZM6 22.4C4.16 21.3067 2.70027 19.84 1.6208 18C0.540267 16.16 0 14.16 0 12C0 8.66667 1.16693 5.8336 3.5008 3.5008C5.8336 1.16693 8.66667 0 12 0C15.3333 0 18.1664 1.16693 20.4992 3.5008C22.8331 5.8336 24 8.66667 24 12C24 14.16 23.4603 16.16 22.3808 18C21.3003 19.84 19.84 21.3067 18 22.4H6Z"
        fill="white"
      />
    </svg>
  );
}

export default LampIcon;
