import React, { useState, useEffect } from "react";

const NumberShuffler = ({
  input,
  onSequenceComplete,
}: {
  input: string;
  onSequenceComplete: (output: string) => void;
}) => {
  const [numbers, setNumbers] = useState<any[]>([]);
  const [clickedOrder, setClickedOrder] = useState<any[]>([]);

  useEffect(() => {
    // Split the input string and shuffle the numbers
    const splitNumbers = input.split(", ");
    const shuffledNumbers = shuffleArray(splitNumbers);
    setNumbers(shuffledNumbers);
    setClickedOrder([]);
  }, [input]);

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // ES6 swap
    }
    return array;
  };

  const handleButtonClick = (number: number | string) => {
    setClickedOrder((prev) => {
      const newOrder = [...prev, number];
      if (newOrder.length === numbers.length) {
        onSequenceComplete(newOrder.join(", "));
      }
      return newOrder;
    });
  };

  return (
    <>
      <div style={{}}>{clickedOrder.join(", ")}</div>
      <div  style={{ display: "flex" }}>
        {numbers.map((number, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(number)}
            className="rounded-xl"
            style={{
              flex: 1,
              border: clickedOrder.includes(number)
                ? "1px solid black"
                : "none",
              opacity: clickedOrder.includes(number) ? 0.75 : 1,
            }}
            disabled={clickedOrder.includes(number)}
          >
            {number}
          </button>
        ))}
      </div>
      <button className="mt-2 w-fit self-center" onClick={() => {
        setClickedOrder([])
      }}>reset</button>
    </>
  );
};

export default NumberShuffler;
