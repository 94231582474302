import { useRef, useState } from "react";
import SendIcon from "../../icons/SendIcon";
import { chatOpenAI } from "../../api/openai";
import { textToSpeechAxios } from "../../api/talk";

interface Message {
  id: number;
  text: string;
  sender: "bot" | "human" | "loading";
}

function Chat() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioSrc, setAudioSrc] = useState<string>("");
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const sendMessage = async () => {
    if (!newMessage.trim()) return;
    let nextId = messages.length ? messages[messages.length - 1].id + 1 : 1;
    setMessages([
      ...messages,
      { id: nextId, text: newMessage, sender: "human" },
      { id: nextId, text: newMessage, sender: "loading" },
    ]);
    setNewMessage("");
    const response = await chatOpenAI(newMessage);
    const audio = await textToSpeechAxios(
      response.choices[0].message.content,
      "jBpfuIE2acCO8z3wKNLl"
    );
    setAudioSrc("text");
    if (audio) {
      const audioBlob = await audio.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      if (audioRef.current) {
        audioRef.current.src = audioUrl;
        audioRef.current.play();
        audioRef.current.onended = (e: any) => {
          e.target.onended = null;
          setAudioSrc("");
        };
      }
    }

    nextId = messages.length ? messages[messages.length - 1].id + 1 : 1;
    setMessages([
      ...messages.filter((msg) => msg.sender !== "loading"),
      { id: nextId, text: newMessage, sender: "human" },
      { id: nextId, text: response.choices[0].message.content, sender: "bot" },
    ]);
  };
  return (
    <div className="w-full h-full flex flex-col">
      <img
        alt={"background"}
        style={{ objectFit: "cover" }}
        className="fixed left-0 top-0 w-full h-full"
        src="/images/background.png"
      />
      <img
        src="/images/minion.png"
        alt="minion"
        className={
          "w-40 mb-4 self-center" +
          (audioSrc === "" ? " breathing" : " talking")
        }
      />
      <div className="flex-1" style={{ zIndex: 100, overflow: "auto" }}>
        {messages.map((message) => (
          <div
            key={message.id}
            className={
              "p-2 chat" +
              (message.sender === "human" ? " chat-end" : " chat-start")
            }
          >
            {message.sender !== "human" && (
              <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  <img
                    alt="Tailwind CSS chat bubble component"
                    src="/images/minion-head.png"
                  />
                </div>
              </div>
            )}
            <div
              className="chat-bubble"
              style={{
                backgroundImage: "url(/images/btn_bg.png)",
                backgroundSize: "cover",
                backgroundPosition: "center -1px",
              }}
            >
              {message.sender === "loading" ? (
                <span className="loading loading-ring loading-md"></span>
              ) : (
                message.text
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center gap-2 mb-2" style={{ zIndex: 100 }}>
        <input
          type="text"
          className="input input-bordered flex-1"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && sendMessage()}
        />
        <button
          className="btn btn-circle btn-primary text-white"
          onClick={sendMessage}
          style={{
            backgroundImage: "url(/images/btn_bg.png)",
            backgroundSize: "cover",
            backgroundPosition: "center -1px",
          }}
        >
          <SendIcon />
        </button>
      </div>
      <audio ref={audioRef}></audio>
    </div>
  );
}

export default Chat;
