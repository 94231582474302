import { API_VERSION, SERVER_BASE_URL, getToken } from "./constants";
import { getAllJSs } from "./journeyStudent";

export const getStudentExams = async (studentId: string) => {
    const { js: jsList } = await getAllJSs(studentId);
    const { journeys: exams } = await getExams(jsList.map((js: any) => js.journeyId));
    return exams;
}

export const getStudentProfessor = async (studentId: string, profId: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        profId, studentId
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    const data = await fetch(`${SERVER_BASE_URL}/${API_VERSION}/journeys/getAllByProfId`, requestOptions)
    const response = await data.json()
    return response
}

export const getExams = async (journeyIds: string[]) => {
    const myHeaders = new Headers();
    myHeaders.append("token", getToken());
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "journeyIds": journeyIds,
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    return fetch(`${SERVER_BASE_URL}/${API_VERSION}/journeys/getAll`, requestOptions)
        .then((response) => response.json())
        .then((result) => { return result; })
        .catch((error) => console.error(error));
}

export const startExam = async (studentId: string, journeyId: string) => {
    const myHeaders = new Headers();
    myHeaders.append("token", getToken());
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "journeyId": journeyId,
        "studentId": studentId
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    return fetch(`${SERVER_BASE_URL}/${API_VERSION}/journeys/start`, requestOptions)
        .then((response) => response.json())
        .then((result) => { return result; })
        .catch((error) => console.error(error));
}

export const startFreestyle = async (studentId: string, topic: string) => {
    const myHeaders = new Headers();
    myHeaders.append("token", getToken());
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "studentId": studentId,
        "instruction": [
            {
                "questionType": topic,
                "questionCount": 25
            }
        ]
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    return fetch(`${SERVER_BASE_URL}/${API_VERSION}/journeys/freestyle`, requestOptions)
        .then((response) => response.json())
        .then((result) => { return result; })
        .catch((error) => console.error(error));
}

export const finishExam = async (studentId: string) => {
    const myHeaders = new Headers();
    myHeaders.append("token", getToken());
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "studentId": studentId
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const examResult = await fetch(`${SERVER_BASE_URL}/${API_VERSION}/journeys/finish`, requestOptions);
        const parsedExamResult = await examResult.json();
        if (parsedExamResult?.score) {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            return parsedExamResult.passed ? "Credit Increased" : "Unfortunely you have not passed";
        } else {
            return "Unfortunely you have not passed"
        }
    } catch (error) {
        return "Unfortunely you have not passed"
    }
}

export const submitAnswer = async (studentId: string, questionId: string, answer: string) => {
    const myHeaders = new Headers();
    myHeaders.append("token", getToken());
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "questionId": questionId,
        "answer": answer,
        "studentId": studentId
    });

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    return fetch(`${SERVER_BASE_URL}/${API_VERSION}/journeys/submitAnswer`, requestOptions)
        .then((response) => response.json())
        .then((result) => { return result; })
        .catch((error) => console.error(error));
}
