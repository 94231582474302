import { useState, useRef, useEffect } from "react";
import DrawableCanvas from "./DrawableCanvas";
import { detectObjects } from "../../../../../model/detect";

const SketchObjectDetector = ({
  session,
  modelInputShape,
  maxOutputBoxesPerClass,
  iouThreshold,
  scoreThreshold,
  initCanvasWidth,
  initCanvasHeight,
  number,
  setNumber,
}: any) => {
  const isDrawingRef = useRef(false);
  const [isDrawing, setIsDrawing] = useState(isDrawingRef.current);
  const imageRef = useRef(null);

  // const boxesCanvasRef = useRef(null);
  const sketchCanvasRef = useRef<HTMLCanvasElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lineWidth, setLineWidth] = useState(6);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [color, setColor] = useState("black");

  useEffect(() => {
    if (sketchCanvasRef.current) {
      const sketchCanvas = sketchCanvasRef.current;
      // const boxesCanvas = boxesCanvasRef.current

      const sketchCtx = sketchCanvas.getContext("2d");

      if (sketchCtx) {
        (sketchCtx as any).willReadFrequently = true;

        // const boxesCtx = boxesCanvas.getContext("2d")
        // boxesCtx.willReadFrequently = true

        const startDrawing = () => {
          isDrawingRef.current = true;
          // document.documentElement.style.overflow = 'hidden'; // TODO
          setIsDrawing(true);
          sketchCtx.beginPath();
        };
        const mouseStartDrawing = (e: any) => {
          startDrawing();
        };

        const touchStartDrawing = (e: any) => {
          e.preventDefault();
          startDrawing();
        };

        sketchCanvas.addEventListener("mousedown", mouseStartDrawing);
        sketchCanvas.addEventListener("touchstart", touchStartDrawing);

        sketchCanvas.style.display = "block";
        // boxesCanvas.style.display = isDrawing ? "none" : "block"

        return () => {
          sketchCanvas.addEventListener("mousedown", mouseStartDrawing);
          sketchCanvas.addEventListener("touchstart", touchStartDrawing);
        };
      }
    }
  }, [isDrawing]);

  useEffect(() => {
    if (sketchCanvasRef.current) {
      (sketchCanvasRef.current.getContext("2d") as any).lineWidth = lineWidth;
      (sketchCanvasRef.current.getContext("2d") as any).strokeStyle = color;
    }
  }, [lineWidth, color]);

  const runDetection = () => {
    if (imageRef.current && sketchCanvasRef.current) {
      (imageRef.current as any).src =
        sketchCanvasRef.current.toDataURL("image/png");
    }
  };

  const detectAndRender = async () => {
    const { boxes } = await detectObjects(
      imageRef.current as any,
      session,
      maxOutputBoxesPerClass,
      iouThreshold,
      scoreThreshold,
      modelInputShape
    );
    console.log("====================================");
    console.log(boxes);
    const newboxes = boxes
      .sort((a: any, b: any) => a.box_xywhn["0"] - b.box_xywhn["0"])
      .filter((item: any) => item.conf > 0.6);
    console.log(newboxes);
    console.log("====================================");
    let detectedNumber = "";
    newboxes.forEach((item: any) => {
      detectedNumber += item.label;
    });
    // renderBoxes(imageRef, boxesCanvasRef, boxes, session.labels); // Draw boxes
    // renderInfo(boxesCanvasRef, speed)
    setNumber(addDotToLeadingZero(detectedNumber as any));
  };

  function addDotToLeadingZero(numStr: string): string {
    // Check if the string starts with '0' and has more digits following it.
    if (numStr.startsWith("0") && numStr.length > 1) {
      // Insert a dot after the leading zero.
      return `0.${numStr.slice(1)}`;
    }
    // If no leading zero or only one zero, return the original string.
    return numStr;
  }

  return (
    <>
      <div className="sketchField">
        <DrawableCanvas
          initCanvasHeight={initCanvasHeight}
          initCanvasWidth={initCanvasWidth}
          canvasRef={sketchCanvasRef}
          setIsDrawing={setIsDrawing}
          runDetection={runDetection}
          isDrawingRef={isDrawingRef}
        />
        <>
          {/*<canvas id="boxesCanvas" ref={boxesCanvasRef} width={initCanvasWidth} height={initCanvasHeight} style={{background: "white"}}/>*/}
          <img
            id="modelInput"
            ref={imageRef}
            src="#"
            alt=""
            onLoad={detectAndRender}
            style={{ visibility: "hidden", display: "none" }}
          />
        </>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (sketchCanvasRef.current) {
              const sketchCtx = sketchCanvasRef.current.getContext("2d");
              sketchCtx?.clearRect(
                0,
                0,
                sketchCanvasRef.current.width,
                sketchCanvasRef.current.height
              );
              (sketchCtx as CanvasRenderingContext2D).fillStyle = "white";
              sketchCtx?.fillRect(
                0,
                0,
                sketchCanvasRef.current.width,
                sketchCanvasRef.current.height
              );
              setNumber("");
            }
          }}
          style={{ opacity: color === "white" ? 0.5 : 1 }}
        >
          reset
        </button>
      </div>
    </>
  );
};

export default SketchObjectDetector;
