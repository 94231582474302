import { useEffect } from "react";

function getMousePosition(e: any, canvas: any) {
  var mouseX = ((e.offsetX * canvas.width) / canvas.clientWidth) | 0;
  var mouseY = ((e.offsetY * canvas.height) / canvas.clientHeight) | 0;
  return { x: mouseX, y: mouseY };
}

const DrawableCanvas = ({
  initCanvasHeight,
  initCanvasWidth,
  canvasRef,
  runDetection,
  setIsDrawing,
  isDrawingRef,
}: any) => {
  let canvas: any, ctx: any;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    canvas = canvasRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ctx = canvas.getContext("2d");
    ctx.willReadFrequently = true;

    ctx.fillStyle = "#FFFFFF"; // White color
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const draw = (x: any, y: any) => {
      if (!isDrawingRef.current) {
        return;
      }
      ctx.lineTo(x, y);
      ctx.stroke();
    };

    const stopDrawing = () => {
      runDetection();
      isDrawingRef.current = false;
      // document.documentElement.style.overflow = 'auto'; //TODO
      setIsDrawing(false);
    };

    const touchDraw = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const canvasRect = canvas.getBoundingClientRect();
      const scrollTop = document.documentElement.scrollTop;
      const { pageX, pageY } = e.touches[0];
      const x = pageX - canvasRect.x;
      const y = pageY - canvasRect.y - scrollTop;
      draw(x, y);
    };

    const mouseDraw = (e: any) => {
      const { x, y } = getMousePosition(e, canvas);
      draw(x, y);
    };

    const mouseStopDrawing = (e: any) => {
      stopDrawing();
    };

    const touchStopDrawing = (e: any) => {
      e.preventDefault();
      stopDrawing();
    };

    canvas.addEventListener("mousemove", mouseDraw);
    canvas.addEventListener("touchmove", touchDraw);
    canvas.addEventListener("mouseup", mouseStopDrawing);
    canvas.addEventListener("touchend", touchStopDrawing);
    return () => {
      canvas.addEventListener("mousemove", mouseDraw);
      canvas.addEventListener("touchmove", touchDraw);
      canvas.addEventListener("mouseup", mouseStopDrawing);
      canvas.addEventListener("touchend", touchStopDrawing);
    };
  }, []);

  return (
    <>
      <canvas
      className=" rounded-xl"
        ref={canvasRef}
        id="sketchCanvas"
        width={initCanvasWidth}
        height={initCanvasHeight}
      />
    </>
  );
};

export default DrawableCanvas;
