export type LevelsType = {
    status: "locked" | "active" | "success" | "";
    top: number;
    left: number;
    stars?: null | 0 | 1 | 2 | 3;
    examId?: string
};


let levels: LevelsType[] = [
    {
        status: "",
        top: 42.13,
        left: 33.72,
        examId: "66278d3c8b84ae41564f5e3c"
    },
    {
        status: "",
        top: 48.25,
        left: 23.85,
        examId: "662b6e51a32f724e17e8a7c1"
    },
    {
        status: "",
        top: 38.75,
        left: 24.85,
        examId: "662b8b9fd87af5389abbb541"
    },
    {
        status: "",
        top: 32.98,
        left: 19.78,
        examId: "662b8bc8d87af5389abbb543"
    },
    {
        status: "",
        top: 31,
        left: 33,
        examId: "662b8bf8d87af5389abbb545"
    },
    {
        status: "",
        top: 38.95,
        left: 52.75,
        examId: "662b8c1fd87af5389abbb547"
    },
    {
        status: "",
        top: 26.33,
        left: 60.42,
        examId: "662b8c3fd87af5389abbb549"
    },
    {
        status: "",
        top: 26.33,
        left: 67.55,
        examId: "662b8c5bd87af5389abbb54b"
    },
    {
        status: "",
        top: 40.17,
        left: 91.68,
        examId: "662b8c7cd87af5389abbb54d"
    },
    {
        status: "",
        top: 55.65,
        left: 67.2,
        examId: "662b8c9bd87af5389abbb54f"
    },
    {
        status: "",
        top: 58.15,
        left: 46.88,
        examId: "660233bac44644093c37ea5e"
    },
    {
        status: "",
        top: 69.57,
        left: 68.7,
        examId: "660233bac44644093c37ea5e"
    },
    {
        status: "",
        top: 77.47,
        left: 42.57,
        examId: "660233bac44644093c37ea5e"
    },
    {
        status: "",
        top: 82.75,
        left: 28.1,
        examId: "660233bac44644093c37ea5e"
    },
    {
        status: "",
        top: 78.88,
        left: 19.42,
        examId: "660233bac44644093c37ea5e"
    },
    {
        status: "",
        top: 69,
        left: 11,
        examId: "660233bac44644093c37ea5e"
    },
];


export default levels