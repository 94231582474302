import { useEffect, useState } from "react";
import Exam from "./components/map/exam/Exam";
import Map from "./components/map/Map";
import levels from "./levels";
import Chat from "./components/chat/Chat";
import { getExamsList, setExamsList } from "./api/constants";
import { getStudentProfessor } from "./api/exams";

function App() {
  const [componentType, setComponentType] = useState<"" | "chat" | "map">("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [currentExamIndex, setCurrentExamIndex] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      urlParams.get("type") &&
      urlParams.get("student") &&
      urlParams.get("prof")
    ) {
      getStudentProfessor(
        urlParams.get("student") as string,
        urlParams.get("prof") as string
      ).then((es) => {
        if (es.journeys.length > 0) {
          setExamsList(es.journeys);
        }
      });
    }
    setComponentType((urlParams.get("type") as any) || "map");
    
  }, []);
  return (
    <>
      {componentType === "map" && (
        <>
          <div style={{ display: selectedLevel !== "" ? "block" : "none" }}>
            <Exam
              examId={selectedLevel}
              examCompleted={(passed: boolean) => {
                setSelectedLevel("");
                if (passed && currentExamIndex >= 0) {
                  levels[currentExamIndex].status = "success";
                  levels[currentExamIndex + 1].status = "active";
                }
              }}
            />
          </div>
          <div style={{ display: selectedLevel === "" ? "block" : "none" }}>
            <Map
              onMapClick={(index: number) => {
                if (index >= 0) {
                  let exam = getExamsList()[index];
                  if (exam) {
                    setSelectedLevel(exam._id);
                    setCurrentExamIndex(index);
                  }
                }
              }}
              onFreeStyleClick={(topic: string) => {
                setSelectedLevel(topic);
                setCurrentExamIndex(-1);
              }}
            />
          </div>
        </>
      )}
      {componentType === "chat" && <Chat />}
    </>
  );
}

export default App;

/*
 */
