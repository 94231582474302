
export const SERVER_BASE_URL = "https://exam-app-pixelprof-6ie2nc57sa-uc.a.run.app"
export const API_VERSION = "api/v1"

let token: string | undefined = undefined
export const setToken = (t: string) => {
    token = t;
    if (token) {
        localStorage.setItem("token", token)
    }
}
export const getToken = (): string => {
    if (!token) {
        let t = localStorage.getItem("token")
        if (!t) {
            token = ""
        } else {
            token = t
        }
    }
    return token
}
let exams: any[] = []
export const setExamsList = (es: any[]) => {
    exams = es;
}
export const getExamsList = () => exams
