import axios from "axios";

const CORE_URL =
    "https://dev-asqme-server.jollyisland-6be8692e.westeurope.azurecontainerapps.io";

const asqmeTalkRequest = async (
    instruction: string,
    audioBase64: string
) => {
    try {
        var formData = new FormData();
        formData.append("instruction", instruction);
        formData.append("embed", "video");
        formData.append("base64", audioBase64);
        const { data } = await axios({
            method: "post",
            url: `${CORE_URL}/talk`,
            data: formData,
        });
        return [data, null];
    } catch (error) {
        return [null, error];
    }
};

const base64Request: any = async (audioData: Blob) => {
    try {
        const formData = new FormData();
        formData.append("file", audioData, Date.now() + ".webm");
        const { data } = await axios({
            data: formData,
            url: `${CORE_URL}/webm_base64`,
            method: "post",
        });
        return [data, null];
    } catch (error) {
        return [null, error];
    }
};

async function textToSpeechAxios(text: string, voiceId: string) {
    const url = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Accept": "audio/mpeg", 'Content-Type': 'application/json', "xi-api-key": "66782fa530ffe5e801b10a0fea9ec112"
            },
            body: JSON.stringify({ text })
        });
        if (!response.ok) {
            return null
        }
        return response; // Assuming the API returns the audio data in response.data
    } catch (error) {
        console.error('Error fetching audio from ElevenLabs API:', error);
        throw error;
    }
}

export { asqmeTalkRequest, base64Request, textToSpeechAxios }